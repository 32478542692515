import React from 'react';
import { Helmet } from "react-helmet";
import cloudServices from "../../Assests/img/TheCloudMantra/CloudServices.webp";
import TheCloudMantraTxt from "../../Assests/img/TheCloudMantra/TheCloudMantraText.webp";
import TheCloudMantraMockup from "../../Assests/img/TheCloudMantra/TheCloudMantraMockup.webp";
import TheCloudMantra1 from "../../Assests/img/TheCloudMantra/cloudmantra1.webp";
import TheCloudMantra2 from "../../Assests/img/TheCloudMantra/cloudmantra2.webp";
import TheCloudMantra3 from "../../Assests/img/TheCloudMantra/cloudmantra3.webp";
import TheCloudMantra4 from "../../Assests/img/TheCloudMantra/cloudmantra4.webp";
import TheCloudMantra6 from "../../Assests/img/TheCloudMantra/cloudmantra6.webp";
import TheCloudMantra7 from "../../Assests/img/TheCloudMantra/cloudmantra7.webp";
import TheCloudMantra8 from "../../Assests/img/TheCloudMantra/cloudmantra8.webp";
import TheCloudMantra9 from "../../Assests/img/TheCloudMantra/cloudmantra9.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const TheCloudMantra = () => {
    return (
        <>
            <Helmet>
                <title>Cloud Solutions Company Web Design | Innovative Development Services | LeapWide</title>
                <meta name="description" content="Discover Leapwide’s innovative web design for The Cloud Mantra, providing a sleek, modern site tailored for cloud solution services and enhanced user experience." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='cloudMantra'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={cloudServices} alt='cloudServices' title='cloudServices'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>The Cloud Mantra</span>
                                </h1>
                                <p className='para-txt'>Cloud Mantra is a cloud services provider founded by seasoned IT experts. Their mission is to democratize access to high-end cloud solutions by offering competitive and innovative cloud services tailored to meet the diverse needs of businesses and individuals.</p>
                            </div>
                        </div>
                        {/* <div className='col-md-1'>
                            <div class="arrow"><a href="#featured-products"></a></div>
                        </div> */}
                    </div>
                    <a href='https://thecloudmantra.com/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />www.thecloudmantra.com</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Building a Solid Foundation: Development Expertise
                                                    </span>
                                                </h3>
                                                {/* <h6 className='overview'>
                                                    Overview</h6> */}
                                                <p className='para-txt'>We began by crafting a compelling brand identity through a well-articulated logo. Our content strategy ensured that the brand's message resonated clearly with the target audience infused with the right keywords for an organic SEO impact. We then translated this vision into a visually stunning and intuitive user interface, backed by robust front-end and back-end development and an intuitive information architecture.
                                                </p>
                                                <h6 className='overview'> The Challenge</h6>
                                                <p className='para-txt'>The Cloud Mantra approached us with a vision to create a dynamic online presence that reflects their expertise in cloud computing solutions. They needed a website that was not only visually appealing but also functional, user-friendly, and optimized for search engines. The goal was to position The Cloud Mantra as a thought leader in the cloud technology space and drive engagement with their target audience.</p>
                                                <h6 className='overview'>Our Approach</h6>
                                                <p className='para-txt'>We began by understanding the core values and objectives of The Cloud Mantra. Our team of designers and developers crafted a modern, responsive website that seamlessly integrates with their brand identity. Utilizing the latest technologies and best practices in web development, we focused on delivering a site that offers a smooth user experience across all devices.</p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>LOGO</li>
                                                    <li>CONTENT STRATEGY</li>
                                                    <li>FRONT END DEVELOPMENT</li>
                                                    <li>BACK END DEVELOPMENT</li>
                                                    <li>UI+UX DESIGN</li>
                                                    <li>INFORMATION ARCHITECTURE</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-md-1 rotate-img'>
                                    <img src={TheCloudMantraTxt} alt='TheCloudMantraTxt' title='TheCloudMantraTxt'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}
                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={TheCloudMantra7} alt='cloudmantra7' title='cloudmantra7'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------------------- */}
                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="2">
                    <img src={TheCloudMantra2} alt='theCloudMantra2' title='theCloudMantra2'></img>
                </div>
                {/* COLOR LINE END */}

                {/* --------------------- */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>DESIGNING WITH A PURPOSE</h6>
                        <h3>Designing with a vision: UI/UX Excellence</h3>
                        <p>At the heart of our process is a deep commitment to user experience. Our UI/UX designers crafted intuitive interfaces that not only looked stunning but also guided users effortlessly through the platform. By conducting thorough user research and usability testing, we created designs that were both aesthetically pleasing and highly functional.
                        </p>
                    </div>
                </div>
                {/* ------------------ */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={TheCloudMantra3} alt='theCloudMantra3' title='theCloudMantra3'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>VISION TO LIFE</h6>
                            <h3>The Result: A Digital Transformation</h3>
                            <p>LeapWide’s collaboration with Cloud Mantra involved a comprehensive approach to digital transformation. Our development team brought the design vision to life through meticulous front-end and back-end development. We employed the latest technologies and industry best practices to create a scalable, high-performance platform. Our focus on clean code and optimization ensured a seamless user experience across devices.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={TheCloudMantraMockup} alt='TheCloudMantraMockup' title='TheCloudMantraMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------- */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={TheCloudMantra6} alt='TheCloudMantra6' title='TheCloudMantra6'></img>
                </div>
                {/* --------------------- */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>VISIBILITY TO B2B CLIENTS</h6>
                        <h3>Stronger Connection with Audience</h3>
                        <p>The Cloud Mantra website now serves as a digital gateway to their cloud services, offering users a seamless experience that’s both informative and engaging. As a result, The Cloud Mantra has seen improved visibility and a stronger connection with their audience.
                        </p>
                    </div>
                </div>
                {/* ------------------ */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={TheCloudMantra8} alt='cloudmantra3' title='cloudmantra3'></img>
                </div >
                {/* --------------------- */}
                < div className='container Personalization' data-aos="fade-up" data-aos-delay="10" >
                    <div className='row'>
                        <h6 className='bold-brand-txt'>NOW</h6>
                        <h3>Better Market Reach</h3>
                        <p>The Cloud Mantra website is now a powerful tool in their digital arsenal, effectively communicating their expertise and services to a global audience. The seamless user experience, combined with strategic SEO practices, has significantly boosted their online presence.
                        </p>
                    </div>
                </div>
                {/* ------------------ */}
                < div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5" >
                    <img src={TheCloudMantra9} alt='TheCloudMantra9' title='TheCloudMantra9'></img>
                </div >
                {/* ------------------ */}
                {/* TESTIMONIAL START */}
                < div className='testimonial' data-aos="fade-up" data-aos-delay="5" >
                    <h2>“The partnership with LeapWide has been instrumental in elevating our digital presence. Their team's expertise in design, development, and strategy has resulted in a website that perfectly aligns with our vision and engages our audience effectively. The platform’s user-friendly interface and exceptional performance have exceeded our expectations.”</h2>
                    <p className='testimonial-source'>- Karan Singh, Founder, Cloud Mantra</p>
                </div >
                {/* TESTIMONIAL END */}
            </div >
        </>

    )
}

export default TheCloudMantra
