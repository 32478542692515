// src/Preloader.js
import React from 'react';
import './Preloader.css'; // You can style your preloader here

const Preloader = () => {
  return (
    <div className="preloader">
      <div id="loader"></div>
    </div>
  );
};

export default Preloader;
