import React from 'react';
import { Helmet } from "react-helmet";
import MarchForOurLivesTxt from "../../Assests/img/MarchForOurLives/MarchForOurLivesTxt.webp";
import MarchForOurLives1 from "../../Assests/img/MarchForOurLives/MarchForOurLives1.webp";
import MarchForOurLives2 from "../../Assests/img/MarchForOurLives/MarchForOurLives2.webp";
import MarchForOurLivesMockup from "../../Assests/img/MarchForOurLives/march-for-our-lives-mockup.webp";
import MarchForOurLives4 from "../../Assests/img/MarchForOurLives/MarchForOurLives4.webp";
import MarchForOurLives5 from "../../Assests/img/MarchForOurLives/MarchForOurLives5.webp";
import MarchForOurLives6 from "../../Assests/img/MarchForOurLives/MarchForOurLives6.webp";
import MarchForOurLivesMain from "../../Assests/img/MarchForOurLives/MarchForOurLives.webp";
import MarchForOurLives3 from "../../Assests/img/MarchForOurLives/MarchForOurLives3.webp";
import MarchForOurLives7 from "../../Assests/img/MarchForOurLives/MarchForOurLives7.webp";
import MarchForOurLives8 from "../../Assests/img/MarchForOurLives/MarchForOurLives8.webp";
import MarchForOurLives9 from "../../Assests/img/MarchForOurLives/MarchForOurLives9.webp";
import MarchForOurLives13 from "../../Assests/img/MarchForOurLives/MarchForOurLives13.webp";
import MarchForOurLivesImg from "../../Assests/img/MarchForOurLives/MarchForOurLivesImg.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const MarchForOurLives = () => {
    return (
        <>
            <Helmet>
                <title>Event Website Design | Engaging & Responsive Solutions | LeapWide</title>
                <meta name="description" content="See how Leapwide created an engaging and effective website for March for Our Lives, focusing on impactful design and development to support event goals." />
            </Helmet>
            <div className='top-spacing MarchForOurLives container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={MarchForOurLivesImg} alt='MarchForOurLivesImg' title='MarchForOurLivesImg'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>March For Our Lives</span>
                                </h1>
                                <p className='para-txt'>March For Our Lives is a youth-led movement ignited by tragedy. They are dedicated to ending the gun violence epidemic through education, activism, and civic engagement. </p>
                            </div>
                        </div>
                        {/* <div className='col-md-1'>
                            <div class="arrow"><a href="#featured-products"></a></div>
                        </div> */}
                    </div>
                    <a href='https://marchforourlives.com/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />marchforourlives.com</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        From protest to powerhouse
                                                    </span>
                                                </h3>
                                                <p className='para-txt'>What began as a spontaneous outcry transformed into a nationwide movement. March For Our Lives, birthed from tragedy and led by youth, demanded a new approach. <br></br><br></br>
                                                    LeapWide partnered with the movement's founders to create a brand that reflected their evolving mission and equipped them with the tools to drive change. Together, we built a platform that fueled voter registration, expanded their grassroots network, and advanced bold policy initiatives. The result? Record youth voter turnout in 2018 and an enduring partnership dedicated to a safer future. Now, the movement’s vision is a future where every community is safe and thriving, free from the fear of gun violence.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>Branding</li>
                                                    <li>Content Strategy</li>
                                                    <li>Information Architecture</li>
                                                    <li>Art Direction</li>
                                                    <li>UX + Visual Design</li>
                                                    <li>Front End Development</li>
                                                    <li>Back End Development</li>
                                                    <li>CRM Integration</li>
                                                    <li>Member Login Portal</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">

                                                <div class="mobile-image" data-reactid="163"><img src={MarchForOurLives1} title='MarchForOurLives1' alt='MarchForOurLives1' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={MarchForOurLivesTxt} alt='MarchForOurLivesTxt' title='MarchForOurLivesTxt'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}
                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={MarchForOurLives2} alt='MarchForOurLives2' title='MarchForOurLives2'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={MarchForOurLives3} alt='MarchForOurLives3' title='MarchForOurLives3'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>BRANDING & VISUAL DESIGN</h6>
                            <h3>A brand as bold as their movement</h3>
                            <p>March For Our Lives began with a powerful logo, but it needed a comprehensive brand identity to match its ambitious goals. We built upon the foundation of the original logo, creating a visual system that captured the energy and urgency of the movement.<br></br><br></br>
                                To support their expanding mission, we transformed the website from a simple signup form into a dynamic hub for information, action, and engagement. Our design focused on empowering supporters, showcasing the organization's policy platform, and tracking the progress of their voter registration bus tour. Throughout this process, we prioritized the students' vision, ensuring their passion and energy were reflected in every aspect of the brand.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={MarchForOurLivesMockup} alt='marchForOurLivesMockup' title='marchForOurLivesMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={MarchForOurLives4} alt='MarchForOurLives4' title='MarchForOurLives4'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={MarchForOurLives5} alt='MarchForOurLives5' title='MarchForOurLives5'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>CHAPTER LOOKUP TOOL</h6>
                        <h3>Empowering a grassroots movement</h3>
                        <p>To build upon the momentum of the 2018 March, March For Our Lives needed a way to harness the energy of its supporters nationwide. We developed a chapter lookup tool that allowed students to connect with local chapters or start their own. By empowering young people to organize at a grassroots level, this tool has been instrumental in expanding the movement's reach and impact.

                        </p>
                    </div>
                </div>
                {/* REPLICA START */}
                {/* ---------------------------- */}
                <div className='replica'>
                    <img src={MarchForOurLives6} alt='MarchForOurLives6' title='MarchForOurLives6'></img>
                </div>
                {/* REPLICA END */}
                {/* ------------------------------ */}
                <div className='replica'>
                    <img src={MarchForOurLivesMain} alt='MarchForOurLives' title='MarchForOurLives'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                <div className='replica'>
                    <img src={MarchForOurLives7} alt='MarchForOurLives7' title='MarchForOurLives7'></img>
                </div>
                {/* REPLICA END */}
                {/* ------------------------------- */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>MEMBER PORTAL</h6>
                        <h3>A digital hub for student activists</h3>
                        <p>With the rapid growth of local chapters, March For Our Lives needed a centralized platform to connect and support student leaders. We collaborated closely with students to create a user-friendly member portal. This platform provides chapter leaders with exclusive resources, event management tools, and direct communication channels. For chapter members, it offers easy access to information and opportunities to get involved. The platform has been instrumental in strengthening the March For Our Lives movement nationwide.
                        </p>
                    </div>
                </div>
                {/* ------------------------------ */}
                <div className='replica'>
                    <img src={MarchForOurLives8} alt='MarchForOurLives8' title='MarchForOurLives8'></img>
                </div>
                {/* REPLICA END */}
                {/* ------------------------------ */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>2020 ELECTION</h6>
                        <h3>Mobilizing voters</h3>
                        <p>Through a strategic partnership with MFOL, LeapWide developed custom landing pages and storytelling tools to encourage members to take targeted actions. Built on WordPress for sustainability and growth, the site has supported various digital engagement and marketing campaigns over the past two years, including online petitions and voter engagement tools.<br></br><br></br>
                            In preparation for the 2020 general election, LeapWide designed and developed a comprehensive page for students to register, locate their polling places, and plan their votes. By integrating embedded forms and external databases, the UX was streamlined to address all user questions on a single page. Promoted extensively by influencers and members, the "Plan to Vote" page became the site’s most viewed page leading up to the election.

                        </p>
                    </div>
                </div>
                {/* TESTIMONIAL START */}
                {/* ---------------------------- */}
                <div className='replica'>
                    <img src={MarchForOurLives9} alt='MarchForOurLives9' title='MarchForOurLives9'></img>
                </div>
                {/* REPLICA END */}
                {/* ----------------------------- */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>ANIMATION</h6>
                        <h3>Showcasing March For Our Lives' impact</h3>
                        <p>In the wake of the unprecedented events of 2020, we crafted a dedicated landing page to illuminate the organization's extraordinary accomplishments. Our primary objective was to seamlessly guide visitors towards downloading the comprehensive impact report. To achieve this, we designed a visually compelling yet minimalist landing page. Smooth on-screen animations effortlessly transported users through key highlights, culminating in a clear call to action. This standalone platform enabled March For Our Lives to distinguish itself from other organizations and effectively communicate its substantial contributions to donors.
                        </p>
                    </div>
                </div>
                {/* -------------------------------- */}
                <video loop="" muted="" autoplay="" playsinline="" class="video marchforourlives" src="https://cms.wideeye.co/wp-content/uploads/2021/09/mfol-impact-report_video_case-study.mp4" data-reactid="319">
                    <source src="https://cms.wideeye.co/wp-content/uploads/2021/09/mfol-impact-report_video_case-study.mp4" type="video/webm" data-reactid="320" />
                    <source src="https://cms.wideeye.co/wp-content/uploads/2021/09/mfol-impact-report_video_case-study.mp4" type="video/mp4" data-reactid="321" />
                    <source src="https://cms.wideeye.co/wp-content/uploads/2021/09/mfol-impact-report_video_case-study.mp4" type="video/ogg" data-reactid="322" />
                </video>
                {/* ----------------------------- */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h6 className='bold-brand-txt'>LOCATION BASED ACTION</h6>
                        <h3>Powering the peace without policing movement</h3>
                        <p>To support March For Our Lives' groundbreaking Peace Without Policing campaign, we developed a dedicated website page outlining the campaign’s goals and inspiring action. The cornerstone of the page is a dynamic, location-based action center tailored to each user's zip code. From joining welcome calls to participating in book clubs or recruiting new members, the page provides clear, actionable steps. This customized platform has transformed into a central hub for individuals to engage with and drive the Peace Without Policing movement forward.

                        </p>
                    </div>
                </div>
                {/* -------------------------------- */}
                <div className='replica'>
                    <img src={MarchForOurLives13} alt='MarchForOurLives13' title='MarchForOurLives13'></img>
                </div>
                {/* ------------------------------------------- */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>"LeapWide is a fantastic partner. Their team's talent and collaborative spirit consistently deliver stunning web experiences. Their guidance has been invaluable in my professional growth."</h2>
                    <p className='testimonial-source'>-Daniel Thomas, Digital Manager / March For Our Lives</p>
                </div>
                {/* TESTIMONIAL END */}
                {/* --------------------------- */}
                {/* RELAUCH START */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left remove-top'>
                                <h3>Impactful Results</h3>
                                <p>The launch of the original March For Our Lives website was a resounding success, and subsequent microsites and projects have exponentially increased its impact. Our team is immensely proud of March For Our Lives' achievements and the collaborative spirit between our organizations.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6 content-right-txt'>
                            <ul>
                                <li><span>27,953</span>
                                    online voter registrations</li>
                                <li><span>273 </span>
                                    chapters established online</li>
                                <li><span>500+ </span>
                                    chapter leaders recruited online</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
            </div>
        </>
    )
}

export default MarchForOurLives
