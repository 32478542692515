import React from 'react';
import { Helmet } from "react-helmet";
import NowNycTitle from "../../Assests/img/NowNyc/NowNycTitle.svg";
import NowNyc1 from "../../Assests/img/NowNyc/NowNyc1.webp";
import NowNyc2 from "../../Assests/img/NowNyc/NowNyc2.webp";
import NowNycMockup from "../../Assests/img/NowNyc/NowNycMockup.webp";
import NowNyc3 from "../../Assests/img/NowNyc/NowNyc3.webp";
import NowNyc4 from "../../Assests/img/NowNyc/NowNyc4.webp";
import NowNycImg from "../../Assests/img/NowNyc/NowNyc.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const NowNyc = () => {
    return (
        <>
            <Helmet>
                <title>City Advocacy Website Design | Professional & Interactive | LeapWide</title>
                <meta name="description" content="Explore Leapwide’s professional and interactive website design for NOW NYC, tailored to support city advocacy with a modern, user-friendly digital experience." />
            </Helmet>
            <div className='top-spacing NowNyc container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={NowNycImg} alt='NowNyc' title='NowNyc'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>National Organization for Women NYC</span>
                                </h1>
                                <p className='para-txt'>Championing women and girls, NOW-NYC ignites change for New York's women and girls through policy and activism. NOW-NYC empowers women and girls by amplifying their voices and demanding accountability.</p>
                            </div>
                        </div>
                        {/* <div className='col-md-1'>
                            <div class="arrow"><a href="#featured-products"></a></div>
                        </div> */}
                    </div>
                    <a href='https://nownyc.org/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />nownyc.org</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-11 '>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <h3>
                                                <span>
                                                    A legacy of leadership
                                                </span>
                                            </h3>
                                            <h6 className='overview'>
                                                Overview</h6>
                                            <p className='para-txt'>For over half a century, the National Organization for Women has been a pioneer for women's rights and equality. In the past decade, NOW-NYC has been at the forefront of progress, uniting members and allies to pass over fifteen laws benefiting New York women. Our 2018 campaign was instrumental in electing a new generation of women's rights champions, with our volunteers knocking on over 5,000 doors across the Northeast.
                                            </p>
                                            <h6 className='overview history'>
                                                The Challenge
                                            </h6>
                                            <p className='para-txt'>The urgent political climate following the 2016 election demanded a renewed focus on activism. NOW-NYC sought to expand its reach and deepen its impact by engaging a wider network of activists. As the founding chapter of the National Organization for Women, we needed a brand refresh that would resonate with our audience while honoring our legacy.
                                            </p>
                                        </div>
                                        <div className='col-md-5  work-we-did'>
                                            <h6>WORK WE DID</h6>
                                            <ul>
                                                <li>Brand strategy</li>
                                                <li>UX design</li>
                                                <li>Visual design</li>
                                                <li>WEBSITE DEVELOPMENT</li>
                                                <li>Content Strategy</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">
                                                <div class="mobile-image" data-reactid="163"><img src={NowNyc1} title='NowNyc1' alt='NowNyc1' /></div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                            <div className='col-md-1 rotate-img'>
                                <img src={NowNycTitle} alt='NowNycTitle' title='NowNycTitle'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={NowNyc1} alt='NowNyc1' title='NowNyc1'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={NowNyc2} alt='NowNyc2' title='NowNyc2'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            {/* <h6>A captivating new site</h6> */}
                            <h3>A modern digital platform</h3>
                            <p>The NOW-NYC website delivers an immersive and accessible user experience. Subtle animations combined with thoughtful design elevate the organization's digital presence. Our content management system empowers the NOW-NYC team to effortlessly update the site with fresh content. By sharing select content with Women’s Justice NOW, we’ve created a unified digital ecosystem.

                            </p>
                            <ul>
                                <li>Visually stunning design</li>
                                <li>User-friendly content management</li>
                                <li>Integrated content sharing</li>
                                <li>Custom issue-focused pages</li>
                                <li>Action-oriented resource modules
                                </li>
                                <li>Seamless content migration
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={NowNycMockup} alt='NowNycMockup' title='NowNycMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={NowNyc3} alt='NowNyc3' title='NowNyc3'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={NowNyc4} alt='NowNyc4' title='NowNyc4'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h3>A seamless launch</h3>
                        <p>
                            Meticulous content planning and in-depth CMS training paved the way for a highly successful website launch. The result is a digital platform that is not only visually stunning but also user-friendly, scalable, and accessible.


                        </p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}
                {/* --------------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>“LeapWide’s ability to manage a fast-paced project while maintaining high organizational standards was crucial for us. As a nonprofit juggling multiple priorities, we appreciated their focus on keeping the project on course. The new website is transformative."</h2>
                    <p className='testimonial-source'>–Mia Garcia, National Organization for Women, New York City Chapter</p>
                </div>
                {/* TESTIMONIAL END */}
                {/* ----------------------- */}
                {/* RELAUCH START */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left remove-top'>
                                <h3>Project Success</h3>
                                <p>The NOW-NYC project was a collaborative effort between two all-women teams. Through meticulous planning and comprehensive CMS training, we achieved a seamless launch. The result is a stunning, user-friendly website that is both scalable and accessible.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6 content-right-txt'>
                            <ul>
                                <li><span> A team composed </span>
                                    entirely of female designers, developers, project managers, and strategists.</li>

                            </ul>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
            </div>
        </>
    )
}

export default NowNyc
