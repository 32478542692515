import React from 'react';
import { Helmet } from "react-helmet";
import CrisisTextLine3 from "../../Assests/img/CrisisTextLine/CrisisTextLine3.webp";
import CrisisTextLineTxt from "../../Assests/img/CrisisTextLine/CrisisTextLineTxt.webp";
import CrisisTextLine1 from "../../Assests/img/CrisisTextLine/CrisisTextLine1.webp";
import CrisisTextLine2 from "../../Assests/img/CrisisTextLine/CrisisTextLine2.webp";
import CrisisTextLine4 from "../../Assests/img/CrisisTextLine/CrisisTextLine4.webp";
import CrisisTextLine5 from "../../Assests/img/CrisisTextLine/CrisisTextLine5.webp";
import CrisisTextLine6 from "../../Assests/img/CrisisTextLine/CrisisTextLine6.webp";
import CrisisTextLineMockup from "../../Assests/img/CrisisTextLine/CrisisTextLineMockup.webp";
import CrisisTextLine from "../../Assests/img/CrisisTextLine/CrisisTextLine.webp";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const HedinghamCastle = () => {
    return (
        <>
            <Helmet>
                <title>Crisis Support Website Design | Responsive & Custom Website | LeapWide</title>
                <meta name="description" content="Discover Leapwide’s responsive and supportive website design for Crisis Text Line, designed to provide critical support through an accessible digital platform." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={CrisisTextLine} alt='CrisisTextLine' title='CrisisTextLine'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>Crisis Text Line</span>
                                </h1>
                                <p className='para-txt'>Crisis Text Line is free, 24/7 support for those in crisis. Crisis Text Line trains volunteers to support people in crisis. They’ve responded to over 100 million messages to date and continue to be a trusted resource to those in need. Text 741741 from anywhere in the US to text with a trained Crisis Counselor. </p>
                            </div>
                        </div>
                    </div>
                    <a href='https://crisistextline.org/' target='_blank' className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />crisistextline.org</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Texter-focused design
                                                    </span>
                                                </h3>
                                                <p className='para-txt'>LeapWide’s primary goal was to make help as accessible as possible. We designed a website that’s supportive, welcoming, and easy to navigate, especially for our primary users: young people. With a focus on mobile experience, we created multiple ways to connect with a Crisis Counselor – SMS, Facebook Messenger, or directly from the website.
                                                    <br></br>By tracking user behavior, we’ve seen significant improvements since launch:<br></br>
                                                    • A surge in text messages to the Crisis Text Line<br></br>
                                                    • Doubled Facebook message volume<br></br>
                                                    • Blazing fast load times<br></br>
                                                    • Top search result on Google<br></br>
                                                    • These results show that our user-centered approach is making a real difference.<br></br>
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>Art Direction</li>
                                                    <li>UX + Visual Design</li>
                                                    <li>Project Management</li>
                                                    <li>Front End Development</li>
                                                    <li>Back End Development</li>
                                                    <li>Animation</li>
                                                    <li>Accessibility</li>
                                                    <li>Multi-lingual</li>
                                                    <li>Web Analytics</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">

                                                <div class="mobile-image" data-reactid="163"><img src={CrisisTextLine1} title='CrisisTextLine1' alt='CrisisTextLine1' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={CrisisTextLineTxt} alt='CrisisTextLineTxt' title='CrisisTextLineTxt'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={CrisisTextLine2} alt='CrisisTextLine2' title='CrisisTextLine2'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={CrisisTextLine3} alt='CrisisTextLine3' title='CrisisTextLine3'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={CrisisTextLine4} alt='CrisisTextLine4' title='CrisisTextLine4'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>INTERACTIVE DESIGN
                            </h6>
                            <h3>A Visual Lifeline</h3>
                            <p>To create a welcoming and informative environment, we employed clear typography, intuitive icons, and gentle illustrations. A key element was an accessible animation showcasing a sample text conversation. This visual representation offers potential texters a glimpse into the support process, helping to alleviate anxiety and encourage them to reach out.
                            </p>

                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={CrisisTextLineMockup} alt='CrisisTextLineMockup' title='CrisisTextLineMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ======================= */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={CrisisTextLine5} alt='CrisisTextLine5' title='CrisisTextLine5'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* -------------------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={CrisisTextLine6} alt='CrisisTextLine6' title='CrisisTextLine6'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* ------------------------/ */}

                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial testimonial-top-spacing' data-aos="fade-up" data-aos-delay="5">
                    <h2>"Launching this website was essential for making our crisis support easily accessible. LeapWide's design puts potential texters first, ensuring they can connect with a Crisis Counselor at every touchpoint."</h2>
                    <p className='testimonial-source'>– Emily Johnson, Growth Marketing Manager, Crisis Text Line</p>
                </div>
                {/* TESTIMONIAL END */}
                {/* --------------------------- */}
                {/* RELAUCH START */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left remove-top'>
                                <h3>Speed is life</h3>
                                <p>The old Squarespace site was slow, inaccessible, and difficult to optimize. Our custom WordPress theme was built with speed and accessibility in mind. By prioritizing non-blocking assets, we slashed load times by almost two seconds, placing us in the top 2% of fastest websites. This means people in crisis can connect with help faster than ever before.
                                    <br></br>  <br></br> Beyond improving user experience, this speed boost is a game-changer for SEO. We’re thrilled to have secured the top spot on Google for "Crisis Text Line"</p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6 content-right-txt'>
                            <ul>
                                <li><span>Blazing fast </span>
                                    The website loads in under a second 60% of the time.</li>
                                <li><span>Top of search </span>
                                    We're the #1 Google result for "Crisis Text Line".</li>
                                <li><span>Increased engagement </span>
                                    We've seen a 150 message daily increase and doubled Facebook messages.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
            </div>
        </>
    )
}

export default HedinghamCastle
