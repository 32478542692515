import React from 'react';
import { Helmet } from "react-helmet";
import labourImg from "../../Assests/img/TheLabourParty/labourImg.webp";
import TentFoundation from "../../Assests/img/TheLabourParty/tent-foundation.webp";
import casestudyHalfimageLabour from "../../Assests/img/TheLabourParty/casestudy_halfimage-labour.webp";
import labourIcons from "../../Assests/img/TheLabourParty/casestudy_fullwidth-labour-icons.webp";
import colorLine from "../../Assests/img/TheLabourParty/casestudy_fullwidth-color-labour.webp";
import mobileLabour from "../../Assests/img/TheLabourParty/casestudy_fullwidth-labour-mobile.webp";
import tabletLabour from "../../Assests/img/TheLabourParty/casestudy_fullwidth-tablet-labour.webp";
import labourTxt from "../../Assests/img/TheLabourParty/casestudy_fullwidth-labour-txt.webp";
import Screencapture from "../../Assests/img/TheLabourParty/screencapture-labour-org-uk.webp";
import tentPattern from "../../Assests/img/TheLabourParty/tent-pattern.webp";
import replica from "../../Assests/img/TheLabourParty/replicaimg.webp";
import clientThumbLabour from "../../Assests/img/TheLabourParty/client-thumb-labour.webp";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import "./TheLabourParty.css"
const IbelloFoundation = () => {

    return (
        <>
            <Helmet>
                <title>Political Website Design & Development | Custom Solutions | LeapWide</title>
                <meta name="description" content="Discover Leapwide’s expertise in creating a dynamic political website for The Labour Party, featuring intuitive design and robust development solutions." />
            </Helmet>
            <div className='top-spacing container-fluid p-0' id='labour-party'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'><img src={labourImg} alt='labourImg' title='labourImg'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span> The Labour
                                        Party UK</span>
                                </h1>
                                <p className='para-txt'>Presently considered as one of the largest political parties in Western Europe, the Labour Party was established in 1900 in the United Kingdom with a center-left ideology.</p>
                            </div>
                        </div>
                    </div>
                    <a href='https://labour.org.uk/' target='_blank' rel="noreferrer" className='for-scale'>
                        <button class="firstbtn firstbtn-2 hover-slide-right">
                            <span><LiaLongArrowAltRightSolid />labour.org.uk</span>
                        </button>
                    </a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Modernizing a 118-year-old political party
                                                    </span>
                                                </h3>
                                                <p className='para-txt'>As an 118 year old establishment, the UK Labour Party has a long history of championing political activism and spearheading acclaimed policies. They have built some of the UK’s most enduring and globally renowned initiatives like the National Health Services (NHS) and contributed a progressive voice to the country’s political space that continues to inspire liberal parties all over the world. The 2017 snap elections under the leadership of Jeremy Corbyn and the overwhelming support received by the party affirmed that the party was making a headway towards 10 Downing Street to form a Government that is for the people, for the many.</p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>CONTENT STRATEGY</li>
                                                    <li>WEBSITE REDESIGN</li>
                                                    <li>INFORMATION<br></br> ARCHITECTURE</li>
                                                    <li>ART DIRECTION</li>
                                                    <li>UX + VISUAL DESIGN</li>
                                                    <li>FRONT END<br></br> DEVELOPMENT</li>
                                                    <li>BACK END DEVELOPMENT</li>
                                                    <li>CRM INTEGRATION</li>
                                                    <li>SITE PERSONALIZATION</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                                                <img src={clientThumbLabour} alt='clientThumbLabour' title='clientThumbLabour'></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={TentFoundation} alt='TentFoundation' title='TentFoundation'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>MODERNIZING AN ICONIC BRAND</h6>
                                <h3>Starting from the beginning</h3>
                                <p>When the Labour Party came to us, their online presence of out of date microsites and web portals posed a challenge for their future plans. We were tasked with upgrading and rebuilding the website and tune it to be more in line with the modern values and tenacity of the party.
                                    <br></br><br></br>
                                    We approached it in three phases. The first phase consisted of revamping the main website. In collaboration with the Labour Party’s team, we went back to the roots. We chose the party’s signature red color and a selection of dynamic new tones that accented it as our original direction. The sans serif fonts were carefully shortlisted for its readability and versatility across a variety of headlines and content templates.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <img src={casestudyHalfimageLabour} alt='casestudyHalfimageLabour' title='casestudyHalfimageLabour'></img>

                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}
                {/* --------------------- */}
                {/* LOGOS GROUP START */}
                <div className='container labourIcons' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <img src={labourIcons} alt='labourIcons' title='labourIcons'></img>
                    </div>
                </div>
                {/* LOGOS GROUP END */}
                {/* --------------------- */}
                {/* COLOR LINE START */}
                <div className='color-line'>
                    <img src={colorLine} alt='colorLine' title='colorLine'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='mobileLabour' data-aos="fade-up" data-aos-delay="10">
                    <img src={mobileLabour} alt='mobileLabour' title='mobileLabour'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <h3>Personalization & Customizations</h3>
                        <p><p>In conjunction with the design aspect of the project, we assessed Labour’s content arsenal to plan a clear content migration strategy spanning the following months and outlining integration with their CRM. The second phase consisted of executing finalized strategies, incorporating new content, adapting it to multiple devices, and adding functions to share seamlessly on social media.
                            <br></br><br></br>
                            In the final stage of the process, we accomplished integrating a JavaScript customization into the CRM’S Engagement API that would and additional features to compel engagement from Labour’s members and supporters.
                        </p></p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}
                {/* --------------------- */}
                {/* TABLET LABOUR START */}
                <div className='tablet-labour' data-aos="fade-up" data-aos-delay="0">
                    <img src={tabletLabour} alt='tabletLabour' title='tabletLabour'></img>
                </div>
                {/* TABLET LABOUR END */}
                {/* ------------------ */}
                {/* KEEP CALM START */}
                <div className='keep-calm' data-aos="fade-up" data-aos-delay="0">
                    <img src={labourTxt} alt='labourTxt' title='labourTxt'></img>
                </div>
                {/* KEEP CALM END */}
                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>THE RELAUNCH</h6>
                            <h3>Making it easier for people to get involved</h3>
                            <p>Labour’s new website has a clear focal point lead by calls to actions to get involved and the mission statement is prominently displayed. With the new design the Labour Party can tweak and scale their complete website parallel to their growing needs and as their goals get more profound for the future.
                            </p>
                            <ul>
                                <li>Integration of CRM</li>
                                <li>HTML 5 Video</li>
                                <li>User experience</li>
                                <li>Strong calls to action</li>
                                <li>Donation pages</li>
                                <li>Embedded social media feed</li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={Screencapture} alt='Screencapture' title='Screencapture'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="10">
                    <img src={tentPattern} alt='tentPattern' title='tentPattern'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={replica} alt='replica' title='replica'></img>
                </div>
                {/* REPLICA END */}
            </div>
        </>
    )
}

export default IbelloFoundation
