import React from 'react';
import { Helmet } from "react-helmet";
import civilrights from "../../Assests/img/CivilRights/civilrights.webp";
import civilrights1 from "../../Assests/img/CivilRights/civilrights1.webp";
import civilrights2 from "../../Assests/img/CivilRights/civilrights2.webp";
import civilrights3 from "../../Assests/img/CivilRights/civilrights3.webp";
import civilrightsmockup from "../../Assests/img/CivilRights/civilrightsmockup.webp";
import CivilRightsTxt from "../../Assests/img/CivilRights/CivilRightsTxt.webp";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const CivilRights = () => {
    return (
        <>
            <Helmet>
                <title>Human Rights Conference Website | Custom App Development | LeapWide</title>
                <meta name="description" content="Leapwide created an engaging and informative website for The Leadership Conference on Civil & Human Rights, enhancing their digital presence with effective web design." />
            </Helmet>
            <div className='top-spacing NowNyc container-fluid p-0' id='hedingham-castle'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={civilrights} alt='civilrights' title='civilrights'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>The Leadership Conference on Civil & Human Rights</span>
                                </h1>
                                <p className='para-txt'>The Leadership Conference on Civil and Human Rights is the nation’s leading coalition for civil and human rights, representing over 200 national organizations. Founded in 1950, it is a steadfast champion for equality and justice.</p>
                            </div>
                        </div>
                    </div>
                    <a href='https://civilrights.org/' target='_blank' className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />civilrights.org</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-11 '>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <h3>
                                                <span>Realizing the promise of America
                                                </span>
                                            </h3>
                                            <p className='para-txt'>The Leadership Conference on Civil and Human Rights is a powerful coalition of over 200 organizations united in the pursuit of a just and equitable society. Since 1950, we have been at the forefront of the fight for civil and human rights, advocating for policies that uplift marginalized communities.
                                            </p>
                                            <p className='para-txt'>In 2018, as our nation faced unprecedented challenges, The Leadership Conference partnered with LeapWide to modernize our digital presence. Our goal was to expand our reach, amplify our message, and effectively respond to the urgent threats to civil rights.


                                            </p>
                                        </div>
                                        <div className='col-md-5  work-we-did'>
                                            <h6>WORK WE DID</h6>
                                            <ul>
                                                <li>Content Strategy</li>
                                                <li>Content Migration</li>
                                                <li>Information Architecture</li>
                                                <li>UX + Visual Design</li>
                                                <li>Project Management</li>
                                                <li>Front End Development</li>
                                                <li>Back End Development</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1 rotate-img'>
                                <img src={CivilRightsTxt} alt='CivilRightsTxt' title='CivilRightsTxt'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="5">
                    <img src={civilrights1} alt='civilrights1' title='civilrights1'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={civilrights2} alt='civilrights2' title='civilrights2'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={civilrights3} alt='civilrights3' title='civilrights3'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            {/* <h6>A captivating new site</h6> */}
                            <h3>A modern platform for change</h3>
                            <p>The redesigned website has transformed The Leadership Conference's digital presence. Built on a flexible WordPress platform, it empowers the organization to adapt and grow alongside its mission. Key features include:
                            </p>
                            <ul>
                                <li>Immersive HTML5 video backgrounds</li>
                                <li>Seamless c3/c4 content management</li>
                                <li>Integrated CRM for efficient engagement </li>
                                <li>Dynamic social media integration </li>
                                <li>Advanced content filtering and organization</li>
                                <li>High-impact visual elements with lightboxes and alert bars</li>
                                <li>Comprehensive content migration</li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={civilrightsmockup} alt='civilrightsmockup' title='civilrightsmockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TESTIMONIAL START */}
                <div className='testimonial testimonial-top-spacing' data-aos="fade-up" data-aos-delay="5">
                    <h2>"The LeapWide team is an extraordinary blend of creativity and professionalism. Their work on our websites was exceptional, but their commitment to our mission made them true partners in our fight for justice."</h2>
                    <p className='testimonial-source'>-Joseph Moore, Executive Vice President for Communications / The Leadership Conference on Civil and Human Rights</p>
                </div>
                {/* TESTIMONIAL END */}

            </div>
        </>
    )
}

export default CivilRights
