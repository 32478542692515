import React from 'react';
import { Helmet } from "react-helmet";
import CommunityCatalystTxt from "../../Assests/img/CommunityCatalyst/CommunityCatalystTxt.webp";
import CommunityCatalyst2 from "../../Assests/img/CommunityCatalyst/CommunityCatalyst2.webp";
import CommunityCatalyst3 from "../../Assests/img/CommunityCatalyst/CommunityCatalyst3.webp";
import CommunityCatalystMockup from "../../Assests/img/CommunityCatalyst/CommunityCatalystMockup.webp";
import CommunityCatalyst4 from "../../Assests/img/CommunityCatalyst/CommunityCatalyst4.webp";
import CommunityCatalyst5 from "../../Assests/img/CommunityCatalyst/CommunityCatalyst5.webp";
import CommunityCatalyst6 from "../../Assests/img/CommunityCatalyst/CommunityCatalyst6.webp";
import CommunityCatalyst1 from "../../Assests/img/CommunityCatalyst/CommunityCatalyst1.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const CommunityCatalyst = () => {
    return (
        <>
        <Helmet>
                <title>Community Engagement Website | Custom Web Development Services | LeapWide</title>
                <meta name="description" content="Leapwide delivered a custom web development solution for Community Catalyst, designed to enhance community engagement with a dynamic and user-friendly site." />
            </Helmet>
            <div className='top-spacing CommunityCatalyst container-fluid p-0' id='community-catalyst'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={CommunityCatalyst1} alt='CommunityCatalyst1' title='CommunityCatalyst1'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>Community Catalyst</span>
                                </h1>
                                <p className='para-txt'>Community Catalyst believes healthcare is a right, not a privilege. Since 1998, they have been transforming healthcare by expanding coverage, improving programs, and building a powerful advocacy network.</p>
                            </div>
                        </div>
                    </div>
                    <a href='http://www.communitycatalyst.org/' target='_blank' className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />communitycatalyst.org</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Transforming healthcare through people power
                                                    </span>
                                                </h3>
                                                <h6 className='overview'>
                                                    Overview</h6>
                                                <p className='para-txt'>The United States boasts immense wealth yet suffers from alarming health disparities. Community Catalyst is a national organization committed to empowering communities to build a healthcare system founded on racial equity and health justice. Our vision is a world where health is a fundamental right for all. Together with partners, we are forging a powerful movement to create a healthcare system that truly serves the people.
                                                </p>
                                                <h6 className='overview history'>
                                                    The Challenge
                                                </h6>
                                                <p className='para-txt'>With a strong mission and proven impact, Community Catalyst was poised for even greater influence. However, the organization faced significant brand challenges. An outdated visual identity, inconsistent messaging, and a lack of brand architecture hindered its ability to connect effectively with audiences. Despite decades of success, the organization needed a unified brand that clearly communicated its values, purpose, and impact.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>UX + VISUAL DESIGN</li>
                                                    <li>FRONT END DEVELOPMENT</li>
                                                    <li>BACK END DEVELOPMENT</li>
                                                    <li>STRATEGIC BRAND STORY</li>
                                                    <li>STAKEHOLDER ENGAGEMENT</li>
                                                    <li>VISUAL BRANDING </li>
                                                    <li>BRAND PROMISE / TAGLINE </li>
                                                    <li>STRATEGIC BRAND BOOK</li>
                                                    <li>CONTENT STRATEGY </li>
                                                    <li>INFORMATION ARCHITECTURE</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158" data-aos="fade-up" data-aos-delay="5">

                                                <div class="mobile-image" data-reactid="163"><img src={CommunityCatalyst2} title='CommunityCatalyst2' alt='CommunityCatalyst2' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={CommunityCatalystTxt} alt='CommunityCatalystTxt' title='CommunityCatalystTxt'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="5">
                    <img src={CommunityCatalyst3} alt='CommunityCatalyst3' title='CommunityCatalyst3'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* PERSONALIZATION START */}
                <div className='container Personalization' data-aos='fade-up' data-aos-delay='10'>
                    <div className='row'>
                        <h3>The Solution</h3>
                        <p>
                        LeapWide embarked on a collaborative journey with Community Catalyst to uncover the heart of their organization. Through extensive employee engagement and workshops, a clear brand identity emerged, centered around a shared purpose: uniting for health justice. This unified vision encompassed the diverse array of national initiatives and programs under the Community Catalyst umbrella.<br></br>
                            <br></br>     Building on this foundation, LeapWide developed a comprehensive rebrand and design system that effectively communicated the organization's role as a catalyst for change. A detailed brand book was created, outlining the brand story, tone of voice, design guidelines, accessibility standards, and photography direction. To facilitate in-depth advocacy and information sharing, we developed dedicated websites for c3 and c4 initiatives.</p>
                    </div>
                </div>
                {/* PERSONALIZATION END */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <p>The interactive platform serves as a dynamic resource hub, showcasing the organization's commitment to health equity through compelling storytelling. By spotlighting the real people impacted by healthcare disparities, the website creates an emotional connection that inspires action. It seamlessly integrates informative content with inspiring narratives, guiding visitors on a journey from awareness to advocacy.

                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={CommunityCatalystMockup} alt='CommunityCatalystMockup' title='CommunityCatalystMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="5">
                    <img src={CommunityCatalyst4} alt='CommunityCatalyst4' title='CommunityCatalyst4'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='tent-pattern'>
                    <img src={CommunityCatalyst5} alt='CommunityCatalyst5' title='CommunityCatalyst5'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='tent-pattern tent-pattern-bottom'>
                    <img src={CommunityCatalyst6} alt='CommunityCatalyst6' title='CommunityCatalyst6'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
            </div>
        </>
    )
}

export default CommunityCatalyst
