import React, { useEffect } from 'react';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import AllRoutes from './Components/AllRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init({
        // once: true,
        disable: "phone",
        duration: 700,
        easing: "ease-out-cubic",
    });
}, []);
  return (
    <>
      <AllRoutes />
  
    </>
  );
}

export default App;
