import React, { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import whiteArrow from "../../Assests/img/white-arrow.png"
import arrow from "../../Assests/img/verticle-arrow.png";
import HedinghamCastle from "../../Assests/img/HedinghamCastle/hedingham-castle-banner.webp";
import clientThumbLabour from "../../Assests/img/TheLabourParty/client-thumb-labour.webp";
import ProChoiceAmerica from "../../Assests/img/LivingStandard/Pro-Choice-America.jpg";
import Lifeup1 from "../../Assests/img/LifeUpHealth/Lifeup1.webp";
import lifeup from "../../Assests/img/lifeup.webp";
import ibell from "../../Assests/img/ibell.webp";
import labour from "../../Assests/img/labour.webp";
import living from "../../Assests/img/living.webp";
import new02 from "../../Assests/img/new02.webp";
import march from "../../Assests/img/march.webp";
import oxford from "../../Assests/img/oxford.webp";
import richman from "../../Assests/img/richman.webp";
import leapWideTeam from "../../Assests/img/LeapWideTeam.webp";
import CloudServices from "../../Assests/img/TheCloudMantra/CloudServices.webp";
import JosepAllenBoone from "../../Assests/img/josephAllenBoone/Josep AllenBoone.webp";
import lifeupCorporateWellness1 from "../../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness1.webp";
import Typed from 'typed.js';
import "./Home.css";
const Home = () => {
    const textRef = useRef(null);

    useEffect(() => {
        const typing = new Typed(textRef.current, {
            strings: ['', 'WEBSITES.', 'SOFTWARE.', 'MOBILE APPS.', 'STRATEGIES.', ' DIGITAL IDENTITIES. '],
            typeSpeed: 100,
            backSpeed: 40,
            loop: true,
        });

        return () => {
            typing.destroy();
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Web Development & Design | App Development | Leapwide</title>
                <meta name="description" content="Leapwide is a friendly web development company offering custom website design, app development, software solutions and consulting for small and medium businesses worldwide." />
            </Helmet>
            <div className='top-spacing'>
                {/* BANNER START */}
                <section id='banner' className='container-fluid'>
                    <div className='container'>
                        <div className='row banner-inner'>
                            <h1>MAKING<br></br>BETTER<br></br></h1>
                            <div className='sliding-text'>
                                <div className='starting-border'> </div>
                                <h1 ref={textRef} className="text"></h1>
                            </div>

                        </div>
                    </div>
                    <div className='arrow'>
                        <a href='#featured-products'><span>FEATURED WORKS</span>
                            <img src={arrow} alt='arrow' title='arrow'></img>
                        </a>
                        {/* <HiOutlineArrowLongDown /> */}
                    </div>
                </section>
                {/* BANNER END */}
                {/* ----------------------- */}
                {/* ABOUT START */}
                <section id='about' className='container-fluid'>
                    <div className='container-fluid for-left-right-spacing'>
                        <div className='row'>
                            <div className='col-md-4 col-sm-12 col-lg-6'></div>
                            <div className='col-md-8 col-sm-12 col-lg-6 text-part'><p className='para-txt' data-aos="fade-up" data-aos-delay="10">LeapWide is a software and digital company where perfect software, websites, mobile apps and all things digital come to life.
                                We turn businesses into stand out brands.</p></div>
                        </div>
                        <div className='row' id='featured-products'>
                            <div className='col-md-6'>
                                <a href='/living-standard'>
                                    <div class="image-container left-sided">
                                        <img className='bigimg' src={ProChoiceAmerica} alt="ProChoiceAmerica" title='ProChoiceAmerica' />
                                        <div class="overlay">
                                            <div className='overlay-all'>
                                                <div className='top-overlay'>
                                                    <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                    <h5>PROJECT</h5>
                                                </div>
                                                <div className='overlay-inner'>
                                                    <div className='text-bottom'>
                                                        <h5>Living Standard</h5>
                                                        <p>Interactive Storytelling, Digital Branding, UX + Visual Design, CRM Integration</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <h5>Living Standard</h5>
                                            <p>Interactive Storytelling, Digital Branding, UX + Visual Design, CRM Integration</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-md-6'>
                                <a href='/hedingham-castle'>
                                    <div class="image-container right-sided" data-aos="fade-up" data-aos-delay="10">
                                        <img className='bigimg' src={HedinghamCastle} alt="HedinghamCastle" title='HedinghamCastle' />
                                        <div class="overlay">
                                            <div className='overlay-all'>
                                                <div className='top-overlay'>
                                                    <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                    <h5>PROJECT</h5>
                                                </div>
                                                <div className='overlay-inner'>
                                                    <div className='text-bottom'>
                                                        <h5>Hedingham Castle</h5>
                                                        <p>Website Redesign, Digital Strategy, Branding & Identity, Email Marketing</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <h5>Hedingham Castle</h5>
                                            <p>Website Redesign, Digital Strategy, Branding & Identity, Email Marketing</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className='container-fluid for-left-right-spacing'>
                        <a href='/the-labour-party'>
                            <div class="image-container full-width" data-aos="fade-up" data-aos-delay="10">
                                <img className='bigimg' src={clientThumbLabour} alt="clientThumbLabour" title='clientThumbLabour' />
                                <div class="overlay">
                                    <div className='overlay-all'>
                                        <div className='top-overlay'>
                                            <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                            <h5>PROJECT</h5>
                                        </div>
                                        <div className='overlay-inner'>
                                            <div className='text-bottom'>
                                                <h5>The Labour Party UK</h5>
                                                <p>Website Redesign, Content Strategy, UX + Visual Design, Front and Back End Development</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info">
                                    <h5>The Labour Party UK</h5>
                                    <p>Website Redesign, Content Strategy, UX + Visual Design, Front and Back End Development</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </section>
                {/* ----------------------- */}
                {/* ABOUT END */}
                {/* ----------------------- */}
                {/* TECHNOLOGY START */}
                <section id='technology' className='container-fluid'>
                    <div className='container-fluid for-left-right-spacing technology-inner'>
                        <div className='row'><h3 className='main-head'>TECHNOLOGY BIG-THINKERS</h3></div>
                        <p className='para-txt'>LeapWide is a full service software company for small to medium scale organizations, visionaries, and big-thinkers. We’re a super cool team of coders, designers, strategists, project managers, planners, and seasoned problem solvers. Our clients are entrepreneurs, leaders and revolutionaries in their field and we help them match their vision with tangible digital results.</p>
                        <a href='/about'><button className='common-btn' data-aos="fade-up" data-aos-delay="10">about us</button></a>
                    </div>
                </section>
                {/* TECHNOLOGY END */}
                {/* ----------------------- */}
                {/* TWO SECTION START */}
                <section id='two-sections' className='container-fluid'>
                    <div className='container-fluid for-left-right-spacing'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <a href='/the-cloud-mantra'>
                                    <div class="image-container left-sided">
                                        <img className='bigimg' src={CloudServices} alt="CloudServices" title='CloudServices' />
                                        <div class="overlay">
                                            <div className='overlay-all'>
                                                <div className='top-overlay'>
                                                    <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                    <h5>PROJECT</h5>
                                                </div>
                                                <div className='overlay-inner'>
                                                    <div className='text-bottom'>
                                                        <h5>The Cloud Mantra</h5>
                                                        <p>Logo & Branding, Website Design, Content Strategy, CRM, Project Management</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <h5>The Cloud Mantra</h5>
                                            <p>Logo & Branding, Website Design, Content Strategy, CRM, Project Management</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-md-6'>
                                <a href='/lifeup-health-coaching'>
                                    <div class="image-container right-sided" data-aos="fade-up" data-aos-delay="10">
                                        <img className='bigimg' src={Lifeup1} alt="Lifeup1" title='Lifeup1' />
                                        <div class="overlay">
                                            <div className='overlay-all'>
                                                <div className='top-overlay'>
                                                    <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                    <h5>PROJECT</h5>
                                                </div>
                                                <div className='overlay-inner'>
                                                    <div className='text-bottom'>
                                                        <h5>LifeUP Health Coaching</h5>
                                                        <p>Website, Branding, Explainer Video, Front and Backend Development</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <h5>LifeUP Health Coaching</h5>
                                            <p>Website, Branding, Explainer Video, Front and Backend Development</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* TWO SECTION END */}
                {/* ----------------------- */}
                {/* SOFTWARE START */}
                <section id='software' className='container-fluid'>
                    <div className='container-fluid for-left-right-spacing software-inner'>
                        <div className='row'>
                            <h3 className='main-head'>MORE THAN JUST SOFTWARE</h3>
                            <ul>
                                <li><p className='para-txt'>Web Development <span>+</span>  Mobile Apps <span>+</span></p> </li>
                                <li><p className='para-txt'>Custom Software <span>+</span>  Interactive Design <span>+</span></p> </li>
                                <li><p className='para-txt'>Web Apps <span>+</span>  Research & UX <span>+</span> Content <span>+</span> </p></li>
                                <li><p className='para-txt'>Explainer Videos <span>+</span> </p></li>
                                <li><p className='para-txt'>Business & Marketing Strategy</p></li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/* SOFTWARE END  */}
                {/* ----------------------- */}
                {/* TWO SECTION START */}
                <section id='two-sections' className='two-sections-bottom container-fluid remove-overlay'>
                    <div className='container-fluid for-left-right-spacing' data-aos="fade-up" data-aos-delay="20">
                        <div className='row'>
                            <div className='col-md-6'>
                                <a href='/lifeup-corporate-wellness'>
                                    <div class="image-container left-sided">
                                        <img className='bigimg' src={lifeupCorporateWellness1} alt="lifeupCorporateWellness1" title='lifeupCorporateWellness1' />
                                        <div class="overlay">
                                            <div className='overlay-all'>
                                                <div className='top-overlay'>
                                                    <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                    <h5>PROJECT</h5>
                                                </div>
                                                <div className='overlay-inner'>
                                                    <div className='text-bottom'>
                                                        <h5>Lifeup Corporate Wellness</h5>
                                                        <p>Website Redesign, Digital Strategy, Branding & Identity, User Experience</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <h5>Lifeup Corporate Wellness</h5>
                                            <p>Website Redesign, Digital Strategy, Branding & Identity, User Experience</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-md-6'>
                                <a href='/joseph-allen-boone'>
                                    <div class="image-container right-sided" data-aos="fade-up" data-aos-delay="10">
                                        <img className='bigimg' src={JosepAllenBoone} alt="JosepAllenBoone" title='JosepAllenBoone' />
                                        <div class="overlay">
                                            <div className='overlay-all'>
                                                <div className='top-overlay'>
                                                    <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                    <h5>PROJECT</h5>
                                                </div>
                                                <div className='overlay-inner'>
                                                    <div className='text-bottom'>
                                                        <h5>Joseph Allen Boone</h5>
                                                        <p>Branding & Identity, UI UX, Email Marketing, Website Development</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <h5>Joseph Allen Boone</h5>
                                            <p>Branding & Identity, UI UX, Email Marketing, Website Development</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* TWO SECTION END */}
                {/* ----------------------- */}
                {/* BLACK SECTION START */}
                <section id='black-section' className='container-fluid'>
                    <div className='container-fluid for-left-right-spacing black-section-inner'>
                        <div className='row'>
                            <h3 className='main-head'>We’ve created award-winning experiences and brands for forward-thinking clients. High level vision rallies serious detail-sweating at every instance of a project. Level up your digital presence.</h3>
                        </div>

                    </div>
                    <div className='container-fluid for-left-right-spacing main-logo'>
                        <div className='row logo-section for-desktop' data-aos="fade-up" data-aos-delay="10">
                            <div className='col-md-3'><img src={lifeup} alt='lifeup' title='lifeup'></img></div>
                            <div className='col-md-3'><img src={ibell} alt='ibell' title='ibell'></img></div>
                            <div className='col-md-3'><img src={labour} alt='labour' title='labour'></img></div>
                            <div className='col-md-3'><img src={living} alt='living' title='living'></img></div>
                        </div>
                        <div className='row logo-section for-mobile'>
                            <img src={lifeup} alt='lifeup' title='lifeup'></img>
                            <img src={ibell} alt='ibell' title='ibell'></img>
                        </div>
                        <div className='row logo-section for-mobile'>
                            <img src={labour} alt='labour' title='labour'></img>
                            <img src={living} alt='living' title='living'></img>
                        </div>
                        <div className='row logo-section for-mobile'>
                            <img src={new02} alt='new02' title='new02'></img>
                            <img src={march} alt='march' title='march'></img>
                        </div>
                        <div className='row logo-section for-mobile'>
                            <img src={oxford} alt='oxford' title='oxford'></img>
                            <img src={richman} alt='richman' title='richman'></img>
                        </div>
                        <div className='row logo-section for-desktop' data-aos="fade-up" data-aos-delay="12">
                            <div className='col-md-3'><img src={new02} alt='new02' title='new02'></img></div>
                            <div className='col-md-3'><img src={march} alt='march' title='march'></img></div>
                            <div className='col-md-3'><img src={oxford} alt='oxford' title='oxford'></img></div>
                            <div className='col-md-3'><img src={richman} alt='richman' title='richman'></img></div>
                        </div>
                    </div>
                </section>
                {/* BLACK SECTION END */}
                {/* ----------------------- */}
                {/* WHO ARE WE? START */}
                <section id='who-we-are' className='blog-type container-fluid'>
                    <div className='container-fluid for-left-right-spacing'>
                        <div className='row'>
                            <div className='col-md-6 left-who-we-are' data-aos="fade-up">
                                <a href='/hire-us' className='blog-left'>
                                    <h6>WHO ARE WE?</h6>
                                    <h3> <span>We at LeapWide think of ourselves as cool in code, best in design and great at consulting.</span></h3>
                                    <img src={leapWideTeam} alt='leapWideTeam' title='leapWideTeam'></img>
                                </a>
                            </div>
                            <div className='col-md-6 right-who-we-are' data-aos="fade-up">
                                <h6>WHAT OUR CLIENTS ARE SAYING...
                                </h6>
                                <div className='testimonial-home'>
                                    <div id="demo" class="carousel slide">
                                        <div class="carousel-indicators">
                                            <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active">01</button>
                                            <button type="button" data-bs-target="#demo" data-bs-slide-to="1">02</button>
                                            <button type="button" data-bs-target="#demo" data-bs-slide-to="2">03</button>
                                        </div>
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <div className='carousal-inner'>
                                                    <h3>It has certainly been a pleasure to work with LeapWide. Your technical and creative expertise has been extraordinary.
                                                    </h3>
                                                    <p>Ibrahim Mohammad, Founder / iBello Foundation</p>
                                                </div>
                                            </div>
                                            <div class="carousel-item">
                                                <div className='carousal-inner'>
                                                    <h3>Communication was wonderful throughout the entire process. The LeapWide team really pulled through. Wonderful!</h3>
                                                    <p>Harish, Managing Director / Richman Property Investors</p>
                                                </div>
                                            </div>
                                            <div class="carousel-item">
                                                <div className='carousal-inner'>
                                                    <h3>I have no hesitation in saying the LeapWide is one of the best software companies out there. Professional and efficient.</h3>
                                                    <p>Steen, Owner & CEO / MessageEasy ApS</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* WHO ARE WE? END */}
            </div>
        </>

    )
}

export default Home
